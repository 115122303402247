import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import GlobalStateService from 'services/GlobalStateService';
import OnlineBookingService from 'services/OnlineBookingService';

import Loader from 'components/reusable/Loader';

class ReferPanel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    render() {
        return (
            <div className="panel">
                <div class="panel-header">
                    Refer a Friend
                </div>
                <div class="panel-body">
                </div>
            </div>
        );
    }
}

export default withRouter(ReferPanel);