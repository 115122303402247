// Libs
import React, { Component } from 'react';

// Services & Helpers
import OnlineBookingService from 'services/OnlineBookingService';
import BootboxHelper from 'helpers/BootboxHelper';
import GlobalStateService from 'services/GlobalStateService';

// Components
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class SubmitSubPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    //--------------------------------------------------------------------------------------------------------------------

    async componentDidMount() {
        const loginDetails = GlobalStateService.getValue('loginDetails');
        if (!loginDetails) {
            this.props.history.replace('/login');
        }
        try {
            await OnlineBookingService.submit(this.props.booking);
            this.props.goNext();
        } catch (message) {
            await BootboxHelper.alert(message);
            this.props.goPrev();
        }
    }
    
    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        return (
            <div className="panel">

                <div className="panel-body">

                    <div className="please-wait">

                        <Loader />

                        Please wait...

                    </div>

                </div>

            </div>
        );
    }

}

export default SubmitSubPage;
