import bootbox from 'bootbox';

export default class BootboxHelper {

    static alert(message, opt) {
        opt = opt || {};
        return new Promise(function (resolve, reject) {
            bootbox.alert({
                ...opt,
                message: message,
                callback: function () {
                    resolve();
                }
            });
            BootboxHelper.iframeScroll();
        });
    }

    static confirm(message, opt) {
        opt = opt || {};
        return new Promise(function (resolve, reject) {
            bootbox.confirm({
                ...opt,
                buttons: {
                    cancel: {
                        className: 'mr-auto',
                        label: 'No'
                    },
                    confirm: {
                        label: 'ml-auto',
                        label: 'Yes'
                    }
                },
                message: message,
                callback: function (result) {
                    resolve(result);
                }
            });
            BootboxHelper.iframeScroll();
        });
    }

    static prompt(message, opt) {
        opt = opt || {};
        return new Promise(function (resolve, reject) {
            bootbox.prompt({
                ...opt,
                message: message,
                callback: function (result) {
                    resolve(result);
                }
            });
            BootboxHelper.iframeScroll();
        });
    }

    static iframeScroll() {
        if (!window.isIframe) {
            return;
        }
        window.setTimeout(() => {
            const element = document.querySelector('.bootbox .modal-content');
            if (element) {
                debugger;
                element.style.top = ((window.parentScrollY || 0) - (window.parentIFrameTop || 0)) + 'px';
            }
        }, 0);
    }
}

