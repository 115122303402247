import moment from 'moment';

export default class TextHelpers {

    static simplifySearchString(str) {
        return (str || '').toLowerCase().replace(/[^a-z0-9 -'&@]/, '').trim();
    }

    static formatPercentage(percentage, numDP) {
        if (typeof (numDP) == 'undefined') {
            numDP = 2;
        }
        const div = Math.pow(10, numDP);
        let value = Math.round(percentage * 100 * div) / div;
        return value;
    }

    static formatDateDiff(currentDate, otherDate) {
        currentDate = moment(currentDate);
        otherDate = moment(otherDate);
        const diff = Math.ceil(otherDate.diff(currentDate, 'days'));

        // Past
        if (diff <= -7) {
            const numWeeks = Math.floor(Math.abs(diff) / 7);
            return numWeeks + ' week' + (numWeeks == 1 ? '' : 's') + ' ago';
        } else if (diff < -1) {
            return (-diff) + ' days ago';
        } else if (diff == -1) {
            return 'Yesterday';
        }

        // Present
        else if (diff == 0) {
            return 'Today';
        }

        // Future
        else if (diff >= 7) {
            const numWeeks = Math.floor(Math.abs(diff) / 7);
            return 'In ' + numWeeks + ' week' + (numWeeks == 1 ? '' : 's');
        } else if (diff > 1) {
            return 'In ' + diff + ' days';
        } else if (diff == 1) {
            return 'Tomorrow';
        }

        return '';
    }

    static formatCurrency(amount, opt) {
        opt = { ...opt };
        if (!opt.currency) {
            opt.currency = {
                thousandSeparator: ',',
                decimalSeparator: '.',
                symbol: '?'
            };
        }

        if (typeof opt.numDP == 'undefined') {
            opt.numDP = 2;
        }
        let result = Number(amount || 0).toFixed(opt.numDP).toLocaleString();
        result = result.replace(/\.00/, '');
        result = result.replace(/(\.[1-9]$)/, '$10');
        //result = result.replace('.', );
        if (opt.commas) {
            var parts = result.split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, opt.currency.thousandSeparator);
            result = parts.join(".");
        }
        if (opt.includeSymbol) {
            if ((amount || 0) >= 0) {
                result = opt.currency.symbol + result;
            } else {
                result = '-' + opt.currency.symbol + result.replace('-', '');
            }
        }
        return result;
    }

    static formatTime(time) {
        const timeParts = time.split(':');
        let amOrPM = 'AM';
        let hours = parseInt(timeParts[0]);
        if (hours >= 12) {
            if (hours > 12) {
                hours -= 12;
            }
            amOrPM = 'PM';
        }
        const minutes = parseInt(timeParts[1]);
        return (/*(hours < 10 ? '0' : '') +*/ hours) + ':' + ((minutes < 10 ? '0' : '') + minutes) + ' ' + amOrPM;
    }

    static formatDuration(duration) {
        const parts = [];
        if (duration >= 60) {
            const numHours = Math.floor(duration / 60);
            parts.push(numHours + 'hr');
            duration %= 60;
        }
        if (duration > 0) {
            parts.push(duration + 'min');
        }
        return parts.join(', ');
    }
}
