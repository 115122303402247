// Libs
import React, { Component } from 'react';
import queryString from 'query-string';

// Services & Helpers
import GlobalStateService from 'services/GlobalStateService';
import OnlineBookingService from 'services/OnlineBookingService';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import Loader from 'components/reusable/Loader';
import PaymentForm from 'components/pages/booking/PaymentForm';

//-------------------------------------------------------------------------------------------------------------------

class ConfirmSubPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    componentDidMount() {
        const { booking } = this.props;
        const loginDetails = GlobalStateService.getValue('loginDetails');

        // Not logged in
        if (!loginDetails) {
            this.props.history.replace('/login');
        }

        // Need to confirm email address first
        else if (!loginDetails.onlineBookingAccount || !loginDetails.onlineBookingAccount.isEmailConfirmed) {
            const qs = queryString.parse(window.location.search);
            this.setState({
                confirmEmailToken: qs.confirmEmailToken
            }, () =>
                this.checkConfirmEmailToken()
            );
        }

        // Booking a patch test
        else if (booking.isPatchTest) {
            this.props.goNext();
        }

        // No services
        else if (booking.services.length == 0 && booking.packages.length == 0) {
            this.props.history.replace('/');
        }
    }

   updateConfirmEmailToken(confirmEmailToken) {
        const allowedLetters = 'ABCDEFGHJKLMNPQRSTUVWXYZ123456789';
        confirmEmailToken = (confirmEmailToken || '').toUpperCase().split('').filter(c => allowedLetters.indexOf(c) != -1).join('');
        this.setState({
            confirmEmailToken
        }, () =>
            this.checkConfirmEmailToken()
        );
    }

    async checkConfirmEmailToken() {
        const { confirmEmailToken } = this.state;
        if (confirmEmailToken && confirmEmailToken.length >= 4) {
            this.setState({ isCheckingConfirmEmailToken: true });
            const { isConfirmed } = await OnlineBookingService.confirmEmail(confirmEmailToken);
            if (isConfirmed) {
                this.props.load(true);
            } else {
                this.setState({
                    confirmEmailError: 'That code doesn\'t seem to be valid. Please check that you\'ve entered it correctly.'
                });
            }
        } else {
            this.setState({ confirmEmailError: null });
        }
        this.setState({ isCheckingConfirmEmailToken: false });
    }

    async resendConfirmEmail() {
        this.setState({ isLoading: true });
        await OnlineBookingService.resendConfirmEmail();
        this.setState({ isLoading: false });
        BootboxHelper.alert('The email has been re-sent. If you still haven\'t received it in the next 5 minutes, please contact the salon.');
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const { salonInfo, booking } = this.props;
        const { isLoading } = this.state;
        const loginDetails = GlobalStateService.getValue('loginDetails');

        if (isLoading || !loginDetails) {
            return (
                <div className="panel">
                    <div className="please-wait">
                        <Loader />
                    </div>
                </div>
            );
        }

        if (!loginDetails.onlineBookingAccount || !loginDetails.onlineBookingAccount.isEmailConfirmed) {
            return this.renderConfirmEmail();
        }
        else if (salonInfo.enableDeposits && booking && !booking.isPatchTest && booking.takeDeposit && booking.depositAmount > 0 && salonInfo.paymentProvider) {
            return this.renderTakeDeposit();
        } else if (salonInfo.enableDeposits && booking && !booking.isPatchTest && booking.depositAmount == 0 && salonInfo.paymentProvider) {
            return this.renderCaptureCardDetails();
        } else {
            return this.renderInner();
        }
    }

    renderConfirmEmail() {
        const { confirmEmailToken, isCheckingConfirmEmailToken, confirmEmailError } = this.state;
        const loginDetails = GlobalStateService.getValue('loginDetails');

        return (<>
            <div className="panel">

                <div className="confirm-email">

                    {/*<p>
                        You need to confirm your email address before submitting this booking.
                    </p>*/}
                    <p>
                        We've sent an email to <b>{loginDetails.onlineBookingAccount.newEmail}</b> containing a code.
                    </p>
                    <p>
                        Please enter the code here in order to confirm your email address:
                    </p>

                    <div className="confirm-email-token-container">
                        <input
                            className="form-control"
                            value={confirmEmailToken}
                            onChange={e => this.updateConfirmEmailToken(e.target.value)}
                            required
                            disabled={isCheckingConfirmEmailToken}
                            maxLength="4"
                        />
                        {isCheckingConfirmEmailToken &&
                            <Loader isInline />
                        }
                        {confirmEmailError &&
                            <div className="alert alert-danger">
                                {confirmEmailError}
                            </div>
                        }
                    </div>

                    <p>
                        Haven't received the email? Please wait 5 minutes and check your junk folder.
                    </p>
                    <div className="resend-email-token">
                        Still haven't received it?

                        <button className="button secondary resend-confirm-email-btn" href="#" onClick={e => { e.preventDefault(); this.resendConfirmEmail() }} disabled={isCheckingConfirmEmailToken}>
                            Send it again
                        </button>
                    </div>

                </div>

            </div>

        </>);
    }

    renderTakeDeposit() {
        const { salonInfo, booking } = this.props;
        return (
            <>
                <PaymentForm
                    booking={booking}
                    salonInfo={salonInfo}
                    setIsBusy={(isBusy) => {
                        this.setState({ isBusy })
                        this.props.setIsBusy(isBusy);
                    }}
                    onComplete={() => {
                        this.props.goNext();
                    }}/>               
            </>
        );
    }

    renderInner() {
        const loginDetails = GlobalStateService.getValue('loginDetails');

        return (
            <div className="panel">

                <div className="confirm-booking">

                    <p>
                        Just one last step! Please check that you're happy with everything<br />
                        in the appointment summary before submitting your request.
                    </p>

                    <button className="button primary confirm-booking-button" onClick={e => this.props.goNext()}>
                        Confirm request
                    </button>

                    <p className="login-info">
                        You are logged in as {loginDetails.customer.firstName} {loginDetails.customer.lastName}
                    </p>

                </div>

            </div>
        );
    }

    renderCaptureCardDetails() {
        const { salonInfo, booking } = this.props;
        return (
            <>
                <PaymentForm
                    booking={booking}
                    salonInfo={salonInfo}
                    setIsBusy={(isBusy) => {
                        this.setState({ isBusy })
                        this.props.setIsBusy(isBusy);
                    }}
                    depositAmount={booking.depositAmount}
                    onComplete={() => {
                        this.props.goNext();
                    }} />
            </>
        );
    }
}

export default ConfirmSubPage;
