// Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';

// Services & Helpers
import OnlineBookingService from 'services/OnlineBookingService';
import TextHelpers from 'helpers/TextHelpers';

// Components
import PaymentForm from 'components/pages/booking/PaymentForm';
//-------------------------------------------------------------------------------------------------------------------

class PayDepositPage extends Component {

    constructor(props) {
        super(props);

        // Init
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.load();
    }

    //--------------------------------------------------------------------------------------------------------------------

    async load() {
        const { confirmApptInfo, payDeposit } = this.props;

        let showPayDeposit = payDeposit;
        let depositAmount = 0, isPaymentComplete = false;

        if (payDeposit) {
            depositAmount = await OnlineBookingService.getDepositAmount(confirmApptInfo.appointmentID);
            if ((depositAmount > 0 && confirmApptInfo.hasDepositPayments)) {
                isPaymentComplete = true;
                showPayDeposit = false;
            }
            else if (!confirmApptInfo.hasDepositPayments) {
                showPayDeposit = true;
            }
        }
        this.setState({
            depositAmount,
            showPayDeposit,
            isPaymentComplete
        })
    }


    async payDeposit() {
        const { salonInfo, confirmApptInfo } = this.props;
        let savedCard = await OnlineBookingService.getSavedCard(confirmApptInfo.customer.customerID);
        if (savedCard && savedCard.consentToSaveCard) {
            if (savedCard && !savedCard.stripeId) {
                this.setState({ paymentProvider: 'evo' });
            } else
                this.setState({ paymentProvider: 'stripe' });
        }

        if (salonInfo.paymentProvider == this.state.paymentProvider) {
            this.setState({ savedCard });
        }
        this.setState({
            showPaymentForm: true,
            showPayDeposit: false
        });
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    async onComplete() {
        const { confirmApptInfo } = this.props;
        const { depositAmount } = this.state;
        this.setState({
            isLoading: true
        });
        const isSuccess = depositAmount > 0 ? await OnlineBookingService.completeApptPayment(confirmApptInfo.appointmentID, confirmApptInfo.customer.customerID) : true;

        //if (isSuccess) {
        //    await OnlineBookingService.confirm(confirmApptInfo.code);
        //    this.props.onConfirm();
        //}

        this.setState({
            isPaymentComplete: isSuccess,
            showPaymentForm: false,
            isLoading: false
        })
    }

    renderPaymentForm() {
        const { salonInfo, confirmApptInfo } = this.props;
        const { depositAmount } = this.state;
        return (
            <div className="page-content-left">
                <PaymentForm
                    salonInfo={salonInfo}
                    setIsBusy={(isBusy) => {
                        this.setState({ isBusy })
                    }}
                    depositAmount={depositAmount}
                    customer={confirmApptInfo.customer}
                    isConfirmApptPage={true}
                    onComplete={() => {
                        this.onComplete();
                    }} />
            </div>
        )
    }

    render() {
        const { salonInfo, confirmApptInfo } = this.props;
        const { showPayDeposit, showPaymentForm, isPaymentComplete, depositAmount } = this.state;

        let content;
        if (confirmApptInfo) {
            const currencyOpts = {
                numDP: 2,
                includeSymbol: true,
                currency: salonInfo.currency
            };
            content = <>
                <h1>Please pay the deposit for your appointment</h1>

                <div className="confirm-appt-field">
                    <div className="field-name">Salon name</div>
                    <div className="field-value">{salonInfo.name}</div>
                </div>

                <div className="confirm-appt-field">
                    <div className="field-name">Client name</div>
                    <div className="field-value">{confirmApptInfo.customer.name}</div>
                </div>

                <div className="confirm-appt-field">
                    <div className="field-name">Appointment date</div>
                    <div className="field-value">{moment(confirmApptInfo.date).format('dddd, Do MMMM YYYY')}</div>
                </div>

                <div className="confirm-appt-field">
                    <div className="field-name">Service(s)</div>
                    <div className="field-value">
                        {confirmApptInfo.services.map((s, index) =>
                            <div key={index}>
                                {s.serviceName} with {s.stylistName} at {moment(s.time).format('hh:mm a')}
                            </div>
                        )}
                    </div>
                </div>
                <div className="confirm-appt-field">
                    <div className="field-name">Deposit amount</div>
                    <div className="field-value">{TextHelpers.formatCurrency(depositAmount, currencyOpts)}</div>
                </div>

                {isPaymentComplete ?
                    <div className="already-confirmed">
                        Thank you for your payment ☺️

                    </div> : <>
                        {showPayDeposit && <button type="button" className="button primary" onClick={() => this.payDeposit()}>Pay deposit</button>}</>
                }
                {showPaymentForm && <div className="page-content">{this.renderPaymentForm()}</div>}


                <div className="change-or-cancel-message">
                    If you need to discuss your booking, please call the salon
                    {!!salonInfo.telephone && <>{' '}on {salonInfo.telephone}</>}.
                </div>
            </>;
        } else {
            content = <>

                <h1>Appointment not found!</h1>

                <p>
                    Sorry, we couldn't find the appointment you're looking for.
                </p>
                <p>
                    It may have been deleted or already checked out.
                </p>
                <p className="mb-0">
                    Please contact the salon to check your appointment.
                </p>

            </>;
        }

        return (
            <div className="page-content">
                <div className="panel confirm-appt-panel">
                    <div className="panel-body">
                        {content}
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(PayDepositPage);