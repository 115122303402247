// Libs
import React, { Component } from 'react';

// Services & Helpers
import OnlineBookingService from 'services/OnlineBookingService';
import FormHelper from 'helpers/FormHelper';
import GlobalStateService from 'services/GlobalStateService';

// Components
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class LogInSubPage extends Component {

    constructor(props) {
        super(props);

        this.updateCustomerField = this.updateCustomerField.bind(this);

        this.formHelper = new FormHelper({
            fields: {
                firstName: {
                    label: 'First Name',
                    validation: { isRequired: true }
                },
                lastName: {
                    label: 'Last Name',
                    validation: { isRequired: true }
                },
                mobileTel: {
                    label: 'Mobile Number',
                    validation: { isRequired: true }
                },
                email: {
                    label: 'Email Address',
                    validation: { isRequired: true }
                },
                referralCode: { label: 'Referral Code' },
                password: {
                    label: 'Password (must be at least 6 characters)', type: 'password',
                    validation: { isRequired: true }
                },
                confirmPassword: {
                    label: 'Confirm Password', type: 'password',
                    validation: { isRequired: true }
                },
                gdprConsent: {
                    boxLabel: () => `Please send me updates from ${this.props.salonInfo.name} with tailored offers and advice`,
                    type: 'checkbox'
                }
            },
            getValue: (fieldName, fieldInfo) => this.state.newCustomer[fieldName],
            setValue: this.updateCustomerField
        });
        
        this.state = {
            isLoading: true,
            page: null,
            newCustomer: {}
        };
    }
    
    //--------------------------------------------------------------------------------------------------------------------

    componentDidMount() {
        if (this.props.param) {
            if (this.props.param == 'register') {
                this.setState({
                    page: 'register'
                });
            } else {
                this.setState({
                    page: 'select-new-password'
                });
            }
        }
        this.load();
        //this.props.removeSpecificBookingInfo();
    }

    async load() {
        const loginDetails = GlobalStateService.getValue('loginDetails');

        if (loginDetails) {
            this.props.goNext();
        } else {
            this.setState({
                isLoading: false,
            });
        }
    }

    updateLoginField(field, value) {
        this.setState({
            [field]: value
        });
    }

    async logIn() {
        const { email, password } = this.state;
        const {
            updateLoginDetails
        } = this.props;

        if (!email || !password) {
            return;
        }
        this.setState({ isLoading: true });
        try {
            const loginInfo = await OnlineBookingService.logIn(email, password);
            updateLoginDetails(loginInfo);
            this.props.goNext();
        } catch (error) {
            this.setState({
                error,
                isLoading: false
            });
        }
    }

    startRegister() {
        const newCustomer = { ...this.state.newCustomer };
        newCustomer.email = this.state.email;
        newCustomer.password = this.state.password;
        this.setState({
            page: 'register',
            error: null
        });
    }

    cancelRegister() {
        this.setState({
            page: '',
            error: null
        });
    }

    startPasswordReset() {
        this.setState({
            page: 'request-password-reset',
            error: null
        });
    }

    async requestPasswordReset() {
        this.setState({ isLoading: true });
        const response = await OnlineBookingService.requestPasswordReset(this.state.email);
        this.setState({
            isLoading: false
        });
        if (response.isSent) {
            this.setState({
                page: 'sent-password-reset',
                error: null
            });
        } else {
            this.setState({
                error: 'Sorry, that email address could not be found. Please try a different email address or register a new account.'
            });
        }
    }
    
    updateCustomerField(fieldName, value) {
        const newCustomer = { ...this.state.newCustomer };
        newCustomer[fieldName] = value;
        this.setState({
            newCustomer: newCustomer
        });
    }

    async register() {
        const {
            updateLoginDetails
        } = this.props;

        this.setState({ isLoading: true });
        try {
            const result = await OnlineBookingService.register(this.state.newCustomer);

            switch (result.status) {
                case 'success':
                    updateLoginDetails({
                        isLoggedIn: true,
                        onlineBookingAccount: result.onlineBookingAccount,
                        customer: result.customer,
                        jwt: result.jwt
                    });
                    this.props.goNext();
                    break;
                case 'alreadyRegistered':
                case 'alreadyRegistering':
                    this.setState({
                        error: 'It looks like you already have an account registered with this email address. Please log in instead.'
                    });
                    break;
            }
            
            this.setState({
                isLoading: false,
                status: result.status
            });
        } catch (error) {
            this.setState({
                error,
                status: 'error',
                isLoading: false
            });
        }
    }

    async updatePassword() {
        this.setState({
            isLoading: true
        });
        try {
            const response = await OnlineBookingService.updatePassword(this.props.param, this.state.password);
            this.setState({
                email: response.email
            }, () => {
                this.logIn();
            });
        } catch (error) {
            this.setState({
                error,
                isLoading: false
            });
        }
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            page,
            isLoading
        } = this.state

        let title, content;
        switch (page || '') {
            case '':
                title = 'Please log in';
                content = this.renderLogin();
                break;
            case 'register':
                title = 'Register a new account';
                content = this.renderRegister();
                break;
            case 'request-password-reset':
                title = 'Password reset';
                content = this.renderRequestPasswordReset();
                break;
            case 'sent-password-reset':
                title = 'Password reset';
                content = this.renderSentPasswordReset();
                break;
            case 'select-new-password':
                title = 'Password reset';
                content = this.renderUpdatePassword();
                break;
        }

        return (<>
            <div className="panel login-panel">
                <div className="panel-header">
                    {title}
	            </div>
                <div className="panel-body">
                    {content}
                </div>
            </div>

            {(page || '') == '' &&
                <div className="panel register-panel">

                    <div className="panel-header">
                        If you don't have an account, please register here
                    </div>

                    <div className="panel-body">

                        <button className="button secondary" onClick={e => this.startRegister()}>
                            Register a new account
                        </button>

                    </div>

                </div>
            }

            {(page || '') == 'register' && !isLoading &&
                <div className="panel login-panel">

                    <div className="panel-header">
                        If you already have an account, please log in here
                    </div>

                    <div className="panel-body">

                        <button className="button secondary" onClick={e => this.cancelRegister()}>
                            Log in
                        </button>

                    </div>

                </div>
            }

        </>);
    }

    renderLogin() {
        const {
            isLoading,
            error,
            email,
            password
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <div className="login-page">

                <form className="login-form" onSubmit={e => this.logIn()}>

                    {error && 
                        <div className="alert alert-danger">
                            {error}
                        </div>
                    }

                    <div className="form-group">
                        <label>Email Address</label>
                        <input type="email" value={email || ''} onChange={e => this.updateLoginField('email', e.target.value)} />
                    </div>

                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" value={password || ''} onChange={e => this.updateLoginField('password', e.target.value)} />
                    </div>

                    <button type="submit" className="button primary">
                        Log in
                    </button>

                </form>

                <button className="button link" onClick={() => this.startPasswordReset()}>
                    I forgot my password
                </button>

            </div>

        </>);
    }

    renderRequestPasswordReset() {
        const {
            isLoading,
            error,
            email
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <div className="login-page">

                <form className="password-reset-form" onSubmit={e => this.requestPasswordReset()}>
                    <p style={{ textAlign: 'center' }}>
                        Please enter your email address. We will email you a link to reset your password.  
                    </p>
                    
                    {error &&
                        <div className="alert alert-danger">
                            {error}
                        </div>
                    }

                    <div className="form-group">
                        <label>Email Address</label>
                        <input type="email" value={email || ''} onChange={e => this.updateLoginField('email', e.target.value)} />
                    </div>

                    <button type="submit" className="button primary mt-3">
                        Send password reset email
                    </button>

                    <button className="button secondary mt-3" onClick={e => this.setState({ page: '' })}>
                        <span className="fa fa-chevron-left"></span>
                        {' '}Back
                    </button>

                    {error &&
                        <button className="button secondary mt-3" onClick={e => this.startRegister()}>
                            Register a new account
                        </button>
                    }

                </form>

            </div>

        </>);
    }

    renderSentPasswordReset() {
        const {
            isLoading,
            email
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <div className="login-page">

                <p style={{ textAlign: 'center' }}>
                    We have sent a password reset email to <b>{email}</b>.
                    <br/>Please click on the link in the email in order to set a new password.
                </p>

                <button className="button secondary" onClick={e => this.setState({ page: '' })}>
                    <span className="fa fa-chevron-left"></span>
                    {' '}Back
                </button>
            </div>

        </>);
    }

    renderUpdatePassword() {
        const {
            isLoading,
            error,
            password
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <div className="login-page">

                <form className="select-new-password-form" onSubmit={e => this.updatePassword()}>
                    <p style={{ textAlign: 'center' }}>
                        Please choose a new password. Your new password must be at least 6 characters long.
                    </p>

                    {error &&
                        <div className="alert alert-danger">
                            {error}
                        </div>
                    }

                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" value={password || ''} onChange={e => this.updateLoginField('password', e.target.value)} />
                    </div>

                    <button type="submit" className="button primary">
                        Update my password and log in
                    </button>

                </form>

            </div>

        </>);
    }

    renderRegister() {
        const {
            isLoading,
            error
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            {error &&
                <div className="alert alert-danger">
                    {error}
                </div>
            }

            <div className="required-field-warning">
                <span>*</span> = Required field
            </div>

            <form onSubmit={e => this.register()} className="register-form">

                <div className="split-form">
                    {this.formHelper.renderFields([
                        'firstName',
                        'lastName',
                        'mobileTel',
                        'email',
                        'password',
                        'confirmPassword',
                        'referralCode'
                    ])}
                </div>

                <div className="gdpr-consent">

                    {this.formHelper.renderFields(['gdprConsent'])}

                </div>

                <button type="submit" className="button primary" onClick={e => this.register()}>
                    Register a new account
                </button>

            </form>

        </>);
    }

}

export default LogInSubPage;
