import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import GlobalStateService from 'services/GlobalStateService';
import OnlineBookingService from 'services/OnlineBookingService';

import FormHelper from 'helpers/FormHelper';

import Loader from 'components/reusable/Loader';

class AccountDetailsPanel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };

        this.updateField = this.updateField.bind(this);
        this.save = this.save.bind(this);

        this.formHelper = new FormHelper({
            fields: {
                firstName: { label: 'First Name' },
                lastName: { label: 'Last Name' },
                mobileTel: { label: 'Mobile' },
                homeTel: { label: 'Home Telephone Number' },
                workTel: { label: 'Work Telephone Number' },
                email: { label: 'Email' },
                gender: {
                    label: 'Gender',
                    type: 'single-select',
                    getOptions: () => {
                        return [
                            { value: '', text: '(Select)' },
                            { value: 'female', text: 'Female' },
                            { value: 'male', text: 'Male' },
                            { value: 'nonbinary', text: 'Non-Binary' }
                        ];
                    }
                },
                pronouns: { label: 'Pronouns' },
                dateOfBirth: {
                    type: 'date',
                    label: 'Date of birth'
                },
                addressLine1: {
                    type: 'text',
                    entity: 'address',
                    field: 'line1',
                    label: 'Address Line 1'
                },
                addressLine2: {
                    type: 'text',
                    entity: 'address',
                    field: 'line2',
                    label: 'Address Line 2'
                },
                addressTown: {
                    type: 'text',
                    entity: 'address',
                    field: 'town',
                    label: 'Address Town'
                },
                addressPostcode: {
                    type: 'text',
                    entity: 'address',
                    field: 'postcode',
                    label: 'Address Postcode'
                },
                addressCounty: {
                    type: 'text',
                    entity: 'address',
                    field: 'county',
                    label: 'Address County'
                },
                source: {
                    type: 'text',
                    label: 'How did you hear about us?'
                },
                canContactByPost: {
                    type: 'checkbox',
                    label: 'Can contact by post'
                },
                canContactByPhone: {
                    type: 'checkbox',
                    label: 'Can contact by phone'
                },
                canContactByEmail: {
                    type: 'checkbox',
                    label: 'Can contact by email'
                },
                canContactBySMS: {
                    type: 'checkbox',
                    label: 'Can contact by SMS'
                },
                sendSMSReminders: {
                    type: 'checkbox',
                    label: 'Send appointment reminders (SMS)'
                },
                sendEmailReminders: {
                    type: 'checkbox',
                    label: 'Send appointment reminders (Email)'
                },
                unsubscribeMarketingEmails: {
                    type: 'checkbox',
                    label: 'Unsubscribe from marketing emails'
                },
            },
            getValue: (fieldName, fieldInfo) => {
                if (fieldInfo.entity == 'address') {
                    return this.state.customer.address[fieldName];
                } else {
                    return this.state.customer[fieldName];
                }
            },
            setValue: this.updateField
        });
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const loginDetails = GlobalStateService.getValue('loginDetails');
        var customer = await OnlineBookingService.getCustomerDetails(loginDetails.customer.customerID);
        this.setState({
            customer: customer,
            saved: false,
            isLoading: false
        });
    }

    updateField(fieldName, value, fieldInfo) {
        const customer = { ...this.state.customer };
        if (fieldInfo.entity == 'address') {
            customer.address[fieldInfo.field || fieldName] = value;
        } else {
            customer[fieldName] = value;
        }
        customer.isOnlineUpdate = true;
        this.setState({
            customer: customer,
            saved: false
        })
    }

    save() {
        OnlineBookingService.saveCustomerDetails(this.state.customer);
        this.setState({
            saved: true
        })
    }

    render() {
        const {
            customer,
            isLoading
        } = this.state;

        if (isLoading) {
            return (
                <div className="panel">
                    <div className="panel-body">
                        <Loader />
                    </div>
                </div>
            );
        }

        return (
            <div className="panel">
                <div class="panel-header">
                    Account Details
                </div>
                <div class="panel-body">

                    <form className="form customer-details-form" onSubmit={e => { e.preventDefault(); this.save() }}>

                        {this.formHelper.renderFields([
                            'firstName',
                            'lastName',
                            'mobileTel',
                            'homeTel',
                            'email',
                            'gender',
                            'pronouns',
                            'dateOfBirth',

                            'addressLine1',
                            'addressLine2',
                            'addressTown',
                            'addressPostcode',
                            'addressCounty',

                            'source',
                            'canContactByPost',
                            'canContactByPhone',
                            'canContactByEmail',
                            'canContactBySMS',
                            'sendSMSReminders',
                            'sendEmailReminders',
                            'unsubscribeMarketingEmails'
                        ])}
                    </form>

                    <div className="button-row">
                    {!this.state.saved &&
                            <button className="button primary mt-3" onClick={e => this.save()} style={{ width: '50%' }}>Save</button>
                    }
                    {this.state.saved &&
                        <button className="button mt-3" disabled={true}>Saved</button>
                    }
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AccountDetailsPanel);