import React, { Component } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import GlobalStateService from 'services/GlobalStateService';
import OnlineBookingService from 'services/OnlineBookingService';
import TextHelpers from 'helpers/TextHelpers';

import BootboxHelper from 'helpers/BootboxHelper';

import Loader from 'components/reusable/Loader';

class BookingsPanel extends Component {

    constructor(props) {
        super(props);

        this.salonCode = GlobalStateService.getValue('salonCode');

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const loginDetails = GlobalStateService.getValue('loginDetails');
        var appts = await OnlineBookingService.getCustomerAppts(loginDetails.customer.customerID, this.props.past);
        this.setState({
            appts: appts,
            isLoading: false
        })
    }

    async bookAgain(appt) {
        OnlineBookingService.setLocalStorage('rebook-' + this.salonCode, appt);
        this.props.history.push('');
    }

    async reschedule(appt) {
        var confirm = true;
        if (appt.status != 'provisional') {
            confirm = await BootboxHelper.confirm('Are you sure you want to reschedule this booking? It will need to be confirmed again.');
        }
        if (confirm) {
            OnlineBookingService.setLocalStorage('reschedule-' + this.salonCode, appt);
            this.props.history.push('/account/reschedule');
        }
    }

    async cancel(appt) {
        const confirm = await BootboxHelper.confirm('Are you sure you want to cancel this booking?');
        if (confirm) {
            await OnlineBookingService.cancelAppt(appt.appointmentID);
            this.load();
        }
    }

    render() {
        const {
            appts
        } = this.state;

        if (this.state.isLoading) {
            return (
                <div className="panel">
                    <div class="panel-header">
                        {this.props.past && <>Past Bookings</>}
                        {!this.props.past && <>Upcoming Bookings</>}
                    </div>
                    <div class="panel-body">
                        <Loader />
                    </div>
                </div>
            );
        }

        if (this.state.appts.length === 0) {
            return (
                <div className="panel">
                    <div class="panel-header">
                        {this.props.past && <>Past Bookings</>}
                        {!this.props.past && <>Upcoming Bookings</>}
                    </div>
                    <div class="panel-body">
                        {this.props.past && <>No past bookings</>}
                        {!this.props.past && <>No upcoming bookings</>}
                    </div>
                </div>
            );
        }

        return (
            <div className="panel">
                <div class="panel-header">
                    {this.props.past && <>Past Bookings</>}
                    {!this.props.past && <>Upcoming Bookings</>}
                </div>
                <div class="panel-body">
                    <table className="table">
                        <thead>
                            <tr>
                                {!this.props.past && <th>Status</th>}
                                <th>Time</th>
                                <th>Appointment</th>
                                <th className="actions-column"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {appts.map(appt =>
                                <tr key={appt.appointmentID}>

                                    <td>
                                        <b>Status: </b>
                                        {(appt.status === 'provisional' || appt.status === 'unconfirmed') && <>Unconfirmed</>}
                                        {(appt.status === 'confirmed' || appt.status === 'waiting' || appt.status === 'withStylist') && <>Confirmed</>}
                                        {appt.status === 'checkedOut' && <>Checked Out</>}
                                        {appt.status === 'noShow' && <>No Show</>}
                                        {appt.status === 'cancelled' && <>Cancelled</>}
                                        <br />
                                        <b>Paid:</b> {TextHelpers.formatCurrency(appt.amountPaid, { numDP: 2, includeSymbol: true, currency: this.props.salonInfo.currency })} <br />
                                        <b>Total:</b> {TextHelpers.formatCurrency(appt.total, { numDP: 2, includeSymbol: true, currency: this.props.salonInfo.currency })}
                                    </td>
                                    <td>
                                        <b>Date: </b>{moment(appt.date).format('Do MMM \'YY')}<br />
                                        <b>Time: </b>{moment(appt.time).format('h:mm a')}
                                    </td>
                                    <td>
                                        {appt.appointmentPackages.length > 0 && <>
                                            <b>Packages:</b><br />
                                            <ul>
                                                {appt.appointmentPackages.map(apptPackage =>
                                                    <li>{apptPackage.package.name} {apptPackage.package.fixedPrice > 0 && <>
                                                       : {TextHelpers.formatCurrency(apptPackage.package.fixedPrice, { numDP: 2, includeSymbol: true, currency: this.props.salonInfo.currency })}
                                                    </>}</li>
                                                )}
                                            </ul>
                                        </>}
                                        {appt.appointmentServices.length > 0 && <>
                                            <b>Services:</b><br />
                                            <ul>
                                                {appt.appointmentServices.map(apptService =>
                                                    <li>{apptService.service.name} by {apptService.stylistName}{apptService.subTotal > 0 && <>
                                                        : {TextHelpers.formatCurrency(apptService.total, { numDP: 2, includeSymbol: true, currency: this.props.salonInfo.currency })}
                                                    </>}</li>
                                                )}
                                            </ul>
                                        </>}
                                    </td>
                                    <td className="actions-column">
                                        <ul className="bookings-button-list">
                                            <li>
                                                <button className="button primary button-small" onClick={e => this.bookAgain(appt)} >
                                                    <span className="fa fa-rotate-right"></span>{' '}
                                                    Book Again
                                                </button>
                                            </li>
                                            {/*{!this.props.past && appt.status !== 'cancelled' &&*/}
                                            {/*    <li>*/}
                                            {/*        <button className="button secondary button-small" onClick={e => this.reschedule(appt)} >*/}
                                            {/*            <span className="fa fa-pencil-alt"></span>{' '}*/}
                                            {/*            Reschedule*/}
                                            {/*        </button>*/}
                                            {/*    </li>*/}
                                            {/*}*/}
                                            {/*{!this.props.past && appt.status !== 'cancelled' &&*/}
                                            {/*    <li>*/}
                                            {/*        <button className="button button-small" onClick={e => this.cancel(appt)}>*/}
                                            {/*            <span className="fa fa-times"></span>{' '}*/}
                                            {/*            Cancel*/}
                                            {/*        </button>*/}
                                            {/*    </li>*/}
                                            {/*}*/}
                                        </ul>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default withRouter(BookingsPanel);