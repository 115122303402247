import BaseService from 'services/BaseService';
import moment from 'moment';

//-------------------------------------------------------------------------------------------------------------------

export default class OnlineBookingService
    extends BaseService {

    static Version = '1.00';

    static getLocalStorage(key, defaultValue) {
        try {
            const value = window.localStorage.getItem(key);
            if (value) {
                return JSON.parse(value);
            } else {
                return defaultValue;
            }
        } catch (e) {
            return defaultValue;
        }
    }

    static setLocalStorage(key, value) {
        try {
            //const replacer = (key, value) => {
            //    if (this[key] instanceof Date) {
            //        return this[key].toUTCString();
            //    }
            //    return value;
            //};
            //const json = JSON.stringify(value, replacer);
            //const tempJSON = JSON.stringify(value);
            const json = JSON.stringify(value);
            window.localStorage.setItem(key, json);
            return true;
        } catch (e) {
            return false;
        }
    }

    static async getSalonInfo() {
        return BaseService.callAPI('get-salon-info');
    }

    static async listServicesByCategory() {
        return BaseService.callAPI('list-services-by-category');
    }

    static async listStylists() {
        return BaseService.callAPI('list-stylists');
    }

    static async listDayAvailability(dateFrom, dateTo, booking) {
        return BaseService.callAPI('list-day-availability', {
            dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(dateTo).format('YYYY-MM-DD'),
            booking
        });
    }

    static async listSlotAvailability(date, booking) {
        return BaseService.callAPI('list-slot-availability', {
            date: moment(date).format('YYYY-MM-DD'),
            booking
        });
    }

    static async logIn(email, password) {
        return BaseService.callAPI('log-in', {
            email, password
        });
    }

    static async checkLogin() {
        return BaseService.callAPI('check-login');
    }

    static async register(customer) {
        return BaseService.callAPI('register', {
            customer
        });
    }

    static async submit(booking) {
        return BaseService.callAPI('submit', {
            ...booking
        });
    }

    static async getLatestPatchTestDate() {
        return BaseService.callAPI('get-latest-patch-test-date');
    }

    static async getNextPatchTestDate() {
        return BaseService.callAPI('get-next-patch-test-date');
    }

    static async requestPasswordReset(email) {
        return BaseService.callAPI('request-password-reset', {
            email
        });
    }

    static async updatePassword(token, password) {
        return BaseService.callAPI('update-password', {
            token, password
        });
    }

    static async getDateInfo(year, month) {
        return BaseService.callAPI(`get-date-info/${year}/${month}`);
    }

    static async getNextAvailableDate(date, booking) {
        return BaseService.callAPI('get-next-available-date', {
            date,
            booking
        });
    }

    static async getSpecificBookingInfo(booking) {
        return BaseService.callAPI('get-specific-booking-info', booking);
    }

    static async initDepositPayment(booking, stripePaymentMethodID, saveCardDetails) {
        return BaseService.callAPI('init-deposit-payment', {
            booking, stripePaymentMethodID, saveCardDetails
        });
    }

    static async initConfirmApptPayment(customerID, depositAmount, stripePaymentMethodID, saveCardDetails) {
        return BaseService.callAPI('init-confirm-appt-payment', {
            customerID, depositAmount, stripePaymentMethodID, saveCardDetails
        });
    }

    static async initDepositPaymentByEvo(depositAmount, customerID) {
        return BaseService.callAPI('init-deposit-payment-by-evo', { depositAmount, customerID });
    }

    static async initDepositPaymentBySavedCardEvo(depositAmount, customerID) {
        return BaseService.callAPI('init-payment-by-saved-card-evo', { depositAmount, customerID });
    }

    static async getResponsePostTransaction(saveCardDetails, customerID) {
        return BaseService.callAPI('get-response-post-transaction', { saveCardDetails, customerID });
    }

    static async isPaymentAlreadyMade() {
        return BaseService.callAPI('is-payment-already-made');
    }

    static async confirmEmail(token) {
        return BaseService.callAPI('confirm-email/' + token);
    }

    static async resendConfirmEmail() {
        return BaseService.callAPI('resend-confirm-email');
    }

    static async getConfirmInfo(code) {
        return BaseService.callAPI('appt/get-confirm-info/' + code);
    }

    static async confirm(code) {
        return BaseService.callAPI('appt/confirm/' + code);
    }

    static async getSavedCard(customerId) {
        const response = await BaseService.callAPI('get-saved-card/' + customerId);
        return response;
    }

    static async cancelAppt(apptID) {
        return BaseService.callAPI('cancel-appt/' + apptID);
    }

    static async getCustomerByCode(customerCode) {
        return BaseService.callAPI('get-customer-by-code/' + customerCode);
    }

    static async getFormTypeBySlug(slug) {
        return BaseService.callAPI('get-form-type-by-slug/' + slug);
    }

    static async getFormLink(formID) {
        return BaseService.callAPI('get-form-link/' + formID);
    }

    static async getCustomerForms(customerID) {
        return BaseService.callAPI('get-customer-forms/' + customerID);
    }

    static async getForm(formID, ) {
        return BaseService.callAPI('get-form/' + formID);
    }

    static async getFormByLink(formTypeID, customerID) {
        return BaseService.callAPI('get-form-by-link/'+formTypeID+'/'+customerID);
    }

    static async getFormResponse(formID) {
        return BaseService.callAPI('get-form-response/' + formID);
    }

    static async saveForm(form) {
        var formID = await BaseService.callAPI('save-form', form);
        return formID;
    }

    static async saveFormResponse(formID, response) {
        await BaseService.callAPI('save-form-response', { formID: formID, formResponse: response });
        return formID;
    }

    static async getCustomerDetails(customerID) {
        return BaseService.callAPI('get-customer-details/' + customerID);
    }

    static async saveCustomerDetails(customer) {
        return BaseService.callAPI('save-customer-details', customer);
    }

    static async getCustomerAppts(customerID, past) {
        return BaseService.callAPI('get-customer-appts/' + customerID + '/' + past);
    }

    static async rescheduleAppt(apptID, booking) {
        return BaseService.callAPI('reschedule-appt/' + apptID, booking);
    }

    static async getSalonList() {
        return BaseService.callAPI('get-salon-list');
    }

    static async getSalonGroupCode() {
        return BaseService.callAPI('get-salon-group-code');
    }

    static async applyPromoCode(code, booking) {
        return BaseService.callAPI('apply-promo-code/' + code, booking);
    }

    static async getPromoCodesList() {
        return BaseService.callAPI('get-promo-codes-list');
    }

    static async getDepositAmount(appointmentID) {
        return BaseService.callAPI('get-deposit-amount/' + appointmentID);
    }

    static async completeApptPayment(appointmentID, customerID) {
        return BaseService.callAPI('complete-appt-payment/' + appointmentID + '/' + customerID);
    }
}
