import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

//-------------------------------------------------------------------------------------------------------------------

class Layout extends Component {
    render() {
        return (

            <div className="main-content">
                <div className="main-content-inner">

                    {this.props.children}

                </div>
            </div>

        );
    }
}

export default withRouter(Layout);