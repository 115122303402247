import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import OnlineBookingService from 'services/OnlineBookingService';
import GlobalStateService from 'services/GlobalStateService';

import AccountSummaryPanel from 'components/pages/AccountSummaryPanel';
import BookingsPanel from 'components/pages/BookingsPanel';
import AccountDetailsPanel from 'components/pages/AccountDetailsPanel';
import ReferPanel from 'components/pages/ReferPanel';
import FormsPanel from 'components/pages/FormsPanel';
import ReschedulePanel from 'components/pages/ReschedulePanel';

import Loader from 'components/reusable/Loader';
import LogInSubPage from 'components/pages/booking/LogInSubPage';
import ConfirmEmail from 'components/pages/ConfirmEmail';

class AccountDashboard extends Component {

    constructor(props) {
        super(props);

        this.refresh = this.refresh.bind(this);
        this.updateLoginDetails = this.updateLoginDetails.bind(this);

        this.salonCode = GlobalStateService.getValue('salonCode');
        var loginDetails = OnlineBookingService.getLocalStorage('login-' + this.salonCode);
        this.updateLoginDetails(loginDetails);

        this.state = {
            isLoading: true
        }
    }

    componentDidMount() {
        this.refreshInterval = setInterval(() => this.refresh(), 30 * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    async refresh() {
        var loginDetails = await OnlineBookingService.checkLogin();
        this.updateLoginDetails(loginDetails);

        this.setState(
            { reload: true },
            () => this.setState({ reload: false })
        )
    }

    updateLoginDetails(loginDetails) {
        if (loginDetails && !loginDetails.customer) {
            loginDetails = null;
        }
        GlobalStateService.setValue('loginDetails', loginDetails);
        OnlineBookingService.setLocalStorage('login-' + this.salonCode, loginDetails);
    }

    renderMainContent() {
        const loginDetails = GlobalStateService.getValue('loginDetails');

        if (loginDetails && loginDetails.onlineBookingAccount.isEmailConfirmed && loginDetails.customer) {
            switch (this.props.match.params.subPage || '') {
                case 'upcoming-bookings':
                    return (<BookingsPanel
                        key='upcoming-bookings'
                        salonInfo={this.props.salonInfo}
                        past={false}
                    />);
                case 'past-bookings':
                    return (<BookingsPanel
                        key='past-bookings'
                        salonInfo={this.props.salonInfo}
                        past={true}
                    />);
                case 'account-details':
                    return (<AccountDetailsPanel />);
                //case 'refer-friend':
                //    return (<ReferPanel />);
                case 'forms':
                    return (<FormsPanel />);
                case 'reschedule':
                    return (<ReschedulePanel
                        salonInfo={this.props.salonInfo}
                        history={this.props.history}
                    />);
                default:
                    return (<BookingsPanel
                        key='upcoming-bookings'
                        salonInfo={this.props.salonInfo}
                        past={false}
                    />);
            }
        }
        if (loginDetails && !loginDetails.onlineBookingAccount.isEmailConfirmed) {
            return (<ConfirmEmail
                salonInfo={this.props.salonCode}
                history={this.props.history}
                load={this.refresh}
            />
            );
        }


        let subPage;
        switch (this.props.match.params.subPage || '') {
            case 'upcoming-bookings':
            case 'past-bookings':
            case 'account-details':
            case 'forms':
            case 'reschedule':
                subPage = '';
                break;
            default:
                subPage = this.props.match.params.subPage;
                break;

        }
        return (
            <LogInSubPage
                goNext={this.refresh}
                updateLoginDetails={this.updateLoginDetails}
                salonCode={this.salonCode}
                salonInfo={this.props.salonInfo}
                param={subPage}
            />
        );
    }

    render() {
        return (
            <div>
                <div className="dashboard-content">

                    <div className="dashboard-left-panel">
                        <div className="panel">
                            <div className="panel-header">
                                My Account
                            </div>
                            <div className="panel-body ">
                                <ul className="dashboard-list">
                                    <li className={'hollow-button '} onClick={e => this.props.history.push('/account/upcoming-bookings')}>
                                        Upcoming Bookings
                                    </li>
                                    <li className={'hollow-button '} onClick={e => this.props.history.push('/account/past-bookings')}>
                                        Past Bookings
                                    </li>
                                    <li className={'hollow-button '} onClick={e => this.props.history.push('/account/account-details')}>
                                        Account Details
                                    </li>
                                    <li className={'hollow-button '} onClick={e => this.props.history.push('/account/forms')}>
                                        Consultation Forms
                                    </li>
                                    {/*<li className={'hollow-button '} onClick={e => this.props.history.push('/account/refer-friend')}>*/}
                                    {/*    Refer a Friend*/}
                                    {/*</li>*/}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="dashboard-main-panel">
                        {this.renderMainContent()}
                    </div>

                    <div className="dashboard-right-panel">
                        <AccountSummaryPanel
                            refresh={this.refresh}
                            salonInfo={this.props.salonInfo}
                        />
                        <button className="button primary" onClick={e => this.props.history.push('')}>Create New Booking</button>
                    </div>
                </div>
            </div>


        );
    }
}

export default withRouter(AccountDashboard);