import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import GlobalStateService from 'services/GlobalStateService';
import OnlineBookingService from 'services/OnlineBookingService';
import TextHelpers from 'helpers/TextHelpers';

class AccountSummaryPanel extends Component {

    constructor(props) {
        super(props);

        this.updateLoginDetails = this.updateLoginDetails.bind(this);

        this.salonCode = GlobalStateService.getValue('salonCode');
        var loginDetails = OnlineBookingService.getLocalStorage('login-' + this.salonCode);
        if (loginDetails && !loginDetails.customer) {
            loginDetails = null;
        }
        GlobalStateService.setValue('loginDetails', loginDetails);
        OnlineBookingService.setLocalStorage('login-' + this.salonCode, loginDetails);


        this.state = {
            isLoading: false
        };
    }

    async componentDidUpdate() {
        const loginDetails = await OnlineBookingService.checkLogin();
        this.updateLoginDetails(loginDetails);
    }

    updateLoginDetails(loginDetails) {
        if (loginDetails && !loginDetails.customer) {
            loginDetails = null;
        }
        GlobalStateService.setValue('loginDetails', loginDetails);
        OnlineBookingService.setLocalStorage('login-' + this.salonCode, loginDetails);
    }

    logOut() {
        const salonCode = GlobalStateService.getValue('salonCode');
        localStorage.removeItem('login-' + salonCode);
        GlobalStateService.setValue('loginDetails', null);
        this.props.history.push('/account/');
    }

    render() {
        const loginDetails = GlobalStateService.getValue('loginDetails');

        if (loginDetails && loginDetails.customer) {
            return (
                <div class="panel acc-details-panel">
                    <div class="panel-header">
                        <span className="fa fa-user"></span>
                        {' ' + loginDetails.customer.firstName + ' ' + loginDetails.customer.lastName}
                    </div>
                    <div class="panel-body">
                        {this.props.salonInfo.showCustAccBalance &&
                            <div className={'customer-account-balance customer-account-balance-' + (loginDetails.customer.accountBalance > 0 ? 'positive' : 'negative')}>
                                <span className="fa fa-piggy-bank"></span>{' '}
                                <span>Account balance:</span>{' '}
                                {TextHelpers.formatCurrency(loginDetails.customer.accountBalance, { numDP: 2, includeSymbol: true, currency: this.props.salonInfo.currency })}
                            </div>
                        }
                        {this.props.salonInfo.showCustLoyalty &&
                            <div>
                                <span className="fa fa-stamp"></span>{' '}
                                <span>Loyalty points:</span>{' '}
                                {loginDetails.customer.numLoyaltyPoints}
                                <br />
                            </div>
                        }
                        <button className="button secondary" onClick={e => this.logOut()}>
                            Log out
                        </button>
                    </div>
                </div>
            );
        }

        return (
            <div class="panel acc-details-panel">
                <div class="panel-header">
                    <span className="fa fa-user"></span>{' '}Log In
                </div>
                <div class="panel-body">
                    Not currently logged in.
                </div>
            </div>);
    }
}

export default withRouter(AccountSummaryPanel);