// Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';

// Services & Helpers
import OnlineBookingService from 'services/OnlineBookingService';

// Components
import Loader from 'components/reusable/Loader';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';

class FormPage extends Component {
    constructor(props) {
        super(props);

        this.updateField = this.updateField.bind(this);
        this.setDateAndSave = this.setDateAndSave.bind(this);
        this.save = this.save.bind(this);

        this.formHelper = new FormHelper({
            fields: {
                name: { label: 'Name', validation: { isRequired: true } },
                email: { label: 'Email', validation: { isRequired: true } },
                phone: { label: 'Mobile Phone', validation: { isRequired: true } },
                over16: {
                    type: 'radio-boolean',
                    label: 'Are you over 16?',
                    validation: { isRequired: true }
                },
                hasScalpSensitivity: {
                    type: 'radio-boolean',
                    label: 'Do you have/have you had any skin or scalp sensitivity?',
                    validation: { isRequired: true }
                },
                scalpSensitivity: { label: 'Please explain when and from what ' },
                hasAllergicReaction: {
                    type: 'radio-boolean',
                    label: 'Have you ever had an allergic reaction with a previous patch test, hair colour, tattoo, henna, permanent makeup, skin product, other?',
                    validation: { isRequired: true }
                },
                allergicReaction: {
                    label: 'Please explain'
                },
                hasSkinAllergy: {
                    type: 'radio-boolean',
                    label: 'Do you have/have you had any other skin allergies?',
                    validation: { isRequired: true }
                },
                skinAllergy: {
                    label: 'Please give details'
                },
                hasMedicalCondition: {
                    type: 'radio-boolean',
                    label: 'Do you have/have you had any health conditions or treatments where you have had radiation, chemotherapy, been prescribed steroids or other medications?',
                    validation: { isRequired: true }
                },
                hasMedicalAdvice: {
                    type: 'radio-boolean',
                    label: 'Have you ever been advised by a medical professional not to use hair colour or other chemical treatments in your hair?',
                    validation: { isRequired: true }
                },
                hasHairLoss: {
                    type: 'radio-boolean',
                    label: 'Have you ever experienced excessive hair loss or damage to your hair?',
                    validation: { isRequired: true }
                },
                hairLoss: {
                    label: 'Please give details'
                },
                hasActivities: {
                    type: 'radio-boolean',
                    label: 'Do you carry out any of the following activities regularly? Swimming / Gym / Sauna / Steam Room / Horse Riding / Other...',
                    validation: { isRequired: true }
                },
                activities: {
                    label: 'Please specify'
                },
                products: {
                    label: 'What Shampoo/Conditioner/Styling Products do you use?',
                    validation: { isRequired: true }
                },
                shampooFreq: {
                    label: 'How often do you need to shampoo your hair?',
                    validation: { isRequired: true }
                },
                heatedTools: {
                    label: 'What heated tools (hairdryers/straighteners/curling tools) do you use and how often?',
                    validation: { isRequired: true }
                },
                hairOpinion: {
                    label: 'What are your likes/dislikes about your hair?',
                    validation: { isRequired: true }
                },
                heardOfUs: {
                    label: 'Where did you hear about us?',
                    validation: { isRequired: true }
                }
            },
            getValue: (fieldName, fieldInfo) => this.state.responses[fieldName],
            setValue: this.updateField,
            isDisabled: (disabled) => this.state.form.filledDate,
            onValidate: (validation) => this.setState({ validation: validation })
        });

        this.state = {
            isLoading: true,
            form: {},
            responses: {},
            completed: false
        }
    }

    componentDidMount() {
        this.load();
    }

    async updateField(fieldName, value, fieldInfo) {
        const responses = { ...this.state.responses };
        responses[fieldName] = value;
        const form = this.state.form;
        form.responses = responses;
        await this.setState({
            form: form,
            responses: responses,
            saved: false
        });
        this.save();
    }

    async load() {
        var formTypeSlug = this.props.match.params.formType;
        var customerCode = this.props.match.params.customer;

        var form, responses;
        if (!customerCode) {
            form = await OnlineBookingService.getForm(formTypeSlug);
            responses = await OnlineBookingService.getFormResponse(formTypeSlug);
        } else {
            var customer = await OnlineBookingService.getCustomerByCode(customerCode);
            var formType = await OnlineBookingService.getFormTypeBySlug(formTypeSlug);
            if (formType && customer) {
                form = await OnlineBookingService.getFormByLink(formType.formTypeID, customer.customerID);
                responses = await OnlineBookingService.getFormResponse(form.formID);
            }
        }

        this.setState({
            form,
            responses,
            isLoading: false
        });
    }

    async setDateAndSave() {
        if (this.state.validation.isValid) {
            const form = this.state.form;
            form.filledDate = moment().toDate();
            this.setState({
                form: form
            })
            OnlineBookingService.saveForm(form);
            await this.setState({
                completed: true
            })
        } else {
            BootboxHelper.alert('Please fill in all sections of the form:<br/>&nbsp;' + this.formHelper.getValidationSummaryHTML());
        }
    }

    async save() {
        var formID = this.state.form.formID;
        await OnlineBookingService.saveFormResponse(formID, this.state.responses);

    }

    render() {
        const {
            isLoading,
            responses,
            completed
        } = this.state;

        if (isLoading) {
            return (
                <div className="panel">
                    <div className="panel-body">
                        <Loader />
                    </div>
                </div>
            );
        }

        if (completed) {
            return (
                <div className="panel">
                    <div className="panel-body">
                        <div>
                            Thank you. Your responses have been saved.
                        </div>
                        <div className="button-row">
                            <button type="button" className="button button-secondary" onClick={e => this.props.history.push('/account/forms')}>
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="panel">
                <div className="panel-body">
                    {!this.state.form && <>
                        Form not found.
                    </>}

                    {this.state.form && <>
                        <h1>{this.state.form.formType.name}</h1>
                        <div className="consultation-form">
                            {this.formHelper.renderFields([
                                'name',
                                'email',
                                'phone',
                                'over16',
                                'hasScalpSensitivity',
                            ])}
                            {(responses?.hasScalpSensitivity == true || responses?.hasScalpSensitivity == 'true') && this.formHelper.renderFormGroup('scalpSensitivity')}
                            {this.formHelper.renderFormGroup('hasAllergicReaction')}
                            {(responses?.hasAllergicReaction == true || responses?.hasAllergicReaction == 'true' )&& this.formHelper.renderFormGroup('allergicReaction')}
                            {this.formHelper.renderFormGroup('hasSkinAllergy')}
                            {(responses?.hasSkinAllergy == true || responses?.hasSkinAllergy == 'true') && this.formHelper.renderFormGroup('skinAllergy')}
                            {this.formHelper.renderFormGroups([
                                'hasMedicalCondition',
                                'hasMedicalAdvice',
                                'hasHairLoss',
                            ])}
                            {(responses?.hasHairLoss == true || responses?.hasHairLoss == 'true') && this.formHelper.renderFormGroup('hairLoss')}
                            {this.formHelper.renderFormGroup('hasActivities')}
                            {(responses?.hasActivities == true || responses?.hasActivities == 'true') && this.formHelper.renderFormGroup('activities')}
                            {this.formHelper.renderFormGroups([
                                'products',
                                'shampooFreq',
                                'heatedTools',
                                'hairOpinion',
                                'heardOfUs'
                            ])}
                        </div>
                        <div className="button-row">
                            <button type="button" className="button button-secondary" onClick={e => this.props.history.push('/account/forms')}>
                                Back
                            </button>

                            {!this.state.form.filledDate &&
                                <button type="button" className="button button-secondary" disabled={this.state.saved} onClick={e => this.setState({ saved: true })}>
                                    {!this.state.saved && <>Save</>}{this.state.saved && <>Saved</>}
                                </button>
                            }

                            <button type="button" className="button button-primary" disabled={this.state.form.filledDate} onClick={e => this.setDateAndSave()}>
                                Save and Complete
                            </button>
                        </div>
                    </>}
                </div>
            </div>
        );
    }
}

export default withRouter(FormPage);