// Libs
import React, { Component } from 'react';

// Services & Helpers
import GlobalStateService from 'services/GlobalStateService';
import BootboxHelper from '../../../helpers/BootboxHelper';

//-------------------------------------------------------------------------------------------------------------------

class SubmittedSubPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    //--------------------------------------------------------------------------------------------------------------------

    componentDidMount() {
        const booking = { ...this.props.booking };

        // Need to log in
        const loginDetails = GlobalStateService.getValue('loginDetails');
        if (!loginDetails) {
            this.props.history.replace('/login');
            return;
        }

        // Booking patch test?
        if (booking.isPatchTest) {
            BootboxHelper.alert('Thank you for booking your patch test. You will now be taken back to your original booking');
            this.props.restoreOriginalBooking(() => {
                this.props.updateBooking(booking)
                this.props.goTo('');
            });
            return;
        }

        // Mark booking as submitted so it is cleared if we try to change pages
        booking.isSubmitted = true;
        this.props.updateBooking(booking, () => {
            // Clear saved booking
            try {
                const salonCode = GlobalStateService.getValue('salonCode');
                window.localStorage.removeItem('booking-' + salonCode);
            } catch (e) {
                // Ignore
            }
        });
    }
    
    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        return (
            <div className="panel">

                <div className="panel-body">

                    {this.renderInner()}

                </div>

            </div>
        );
    }

    renderInner() {

        return (<>

            <div className="submitted">
                <h2>
                    Wonderful! Thanks so much for your request!*
                </h2>
                <p>
                    This has been sent through to us and we will be in touch to confirm ASAP 😊
                </p>
                <p>
                    Just to explain why it's not automatically booked, it may
                    be that we need to adjust things on our end to ensure you're booked in for the right service(s)
                    and for any last minute appointments, we just need to make sure we have any relevant products needed.
                </p>
                <p>
                    *Don't worry, if you've paid a deposit for your appointment - if any changes need to be made, we will be in contact very soon :)
                </p>
            </div>

        </>);
    }
}

export default SubmittedSubPage;
