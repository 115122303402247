// Libs
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import DatePicker from 'components/reusable/DatePicker';

//-------------------------------------------------------------------------------------------------------------------

export default class FormHelper {

    constructor(opt) {
        opt = opt || {
            className: '',
            fields: {},
            getValue: (fieldName, fieldInfo) => console.log('Please specify getValue'),
            setValue: (fieldName, value, fieldInfo) => console.log('Please specify setValue')
        };
        this.fields = opt.fields;
        this.getValue = opt.getValue;
        this.setValue = opt.setValue;
        this.className = opt.className;
        this.onBlur = opt.onBlur;
        this.onValidate = opt.onValidate;
        this.getValidation = opt.getValidation || (() => null);
        this.renderFieldTemplate = opt.renderField || this.standardRenderFieldTemplate;
        this.isDisabled = opt.isDisabled;

        this.validation = {
            isValid: false,
            hasValidated: {},
            errors: {}
        };
    }

    runOnBlur(fieldName, value, fieldInfo) {
        if (value != fieldInfo.lastValue && (this.onBlur || fieldInfo.onBlur)) {
            fieldInfo.lastValue = value;
            let stop = false;
            if (fieldInfo.onBlur) {
                if (fieldInfo.onBlur(value, fieldInfo) === false) {
                    stop = true;
                }
            }
            if (this.onBlur && !stop) {
                this.onBlur(fieldName, value, fieldInfo);
            }
        }
    }

    renderFormGroups(fieldNames, validation) {
        if (!fieldNames) {
            fieldNames = Object.keys(this.fields);
        }
        return fieldNames.filter(fn => !!fn).map(fieldName =>
            <React.Fragment key={fieldName}>
                {this.renderFormGroup(fieldName, validation)}
            </React.Fragment>
        );
    }

    renderFormGroup(fieldName, validation) {
        if (!fieldName) return;
        let fieldInfo = this.fields[fieldName];
        if (!fieldInfo) {
            fieldInfo = {
                label: fieldName
            };
        }

        const formGroupClasses = ['form-group'];
        if (fieldInfo.className) {
            formGroupClasses.push(fieldInfo.className);
        }
        if (fieldInfo.validation) {
            if (fieldInfo.validation.isRequired) {
                formGroupClasses.push('field-required');
            }
            if (validation && validation.errors[fieldName] && validation.errors[fieldName].length > 0) {
                formGroupClasses.push('field-error');
            }
        }

        const fieldRef = React.createRef();

        //let label = fieldInfo.label;
        //if (MiscHelpers.isFunction(label)) {
        //    label = label();
        //}

        return (
            <div className={formGroupClasses.join(' ')}>
                {/*{label &&*/}
                {/*    <label>*/}
                {/*        {label}*/}
                {/*        {!!fieldInfo.getLabelExtras && fieldInfo.getLabelExtras(fieldInfo, fieldRef)}*/}
                {/*    </label>*/}
                {/*}*/}
                {fieldInfo.description &&
                    <div className="description">
                        {fieldInfo.description}
                    </div>
                }
                {this.renderField(fieldName, validation, fieldRef)}
            </div>
        );
    }
    
    renderFields(fieldNames, options) {
        return fieldNames.map(fieldName =>
            <React.Fragment key={fieldName}>
                {this.renderField(fieldName, options)}
            </React.Fragment>
        );
    }

    renderField(fieldName, options) {
        if (!fieldName) return;
        const validation = this.getValidation();
        let fieldComponent, fieldInfo, validationErrorsComponent;

        // If fieldname is a function, call it to get the component
        if ({}.toString.call(fieldName) === '[object Function]') {
            fieldComponent = <>{fieldName()}</>;
            fieldInfo = null;
            validationErrorsComponent = null;
        } else if (fieldName == '-') {
            fieldComponent = null;
            fieldInfo = null;
            validationErrorsComponent = null;
        } else {
            // Regular field
            fieldComponent = this.getField(fieldName, validation);
            fieldInfo = this.fields[fieldName];

            // Get validation errors
            validationErrorsComponent = this.renderValidation(fieldName, validation);
        }

        // Get result and return
        const result = this.renderFieldTemplate(
            fieldName,
            fieldInfo,
            fieldComponent,
            validation && validation.errors && validation.errors[fieldName],
            validationErrorsComponent,
            options
        );
        return result;
    }

    renderValidation(fieldName, validation) {
        if (validation && validation.errors && validation.errors[fieldName] && validation.errors[fieldName].length > 0) {
            return <>
                {validation.errors[fieldName].map((ve, index) =>
                    <span className="validation-error" key={ve + index}>{ve}</span>
                )}
            </>;
        } else {
            return null;
        }
    }

    standardRenderFieldTemplate(fieldName, fieldInfo, fieldComponent, errors, validationErrorsComponent, options) {
        options = {
            className: 'form-group',
            ...options
        };
        if (fieldInfo && fieldInfo.validation && fieldInfo.validation.isRequired) {
            options.className += ' required';
        }
        if (fieldComponent) {
            return (
                <div className={options.className}>
                    {fieldInfo &&
                        <label className={errors && errors.length > 0 ? 'field-error' : ''}>
                            {fieldInfo.label}
                        </label>
                    }
                    {fieldComponent}
                    {validationErrorsComponent}
                </div>
            );
        } else {
            return (
                <div className={options.className}>
                </div>
            );
        }
    }

    getField(fieldName, validation) {
        if (!fieldName) return;
        let fieldInfo = this.fields[fieldName];
        if (!fieldInfo) {
            fieldInfo = {
                type: 'text',
                label: fieldName
            };
        }
        fieldInfo.type = fieldInfo.type || 'text';

        let isDisabled = fieldInfo.isDisabled || this.isDisabled;
        isDisabled = isDisabled && {}.toString.call(isDisabled) === '[object Function]' ? isDisabled() : !!isDisabled;

        let value = this.getValue(fieldInfo.field || fieldName, fieldInfo) || '';
        if (fieldInfo.type == 'radio-boolean') {
            value = this.getValue(fieldInfo.field || fieldName, fieldInfo);
        }
        if (fieldInfo.lastValue === null || typeof(fieldInfo.lastValue) === 'undefined') {
            fieldInfo.lastValue = value;
        }

        // Determine class
        let classNames = [];
        if (this.className) classNames.push(this.className);
        if (fieldInfo.className) classNames.push(fieldInfo.className);
        if (validation && validation.errors && validation.errors[fieldName] && validation.errors[fieldName].length > 0) {
            classNames.push('field-error');
        }
        classNames = classNames.join(' ');

        // Get box label
        let boxLabel = fieldInfo.boxLabel;
        if (typeof (boxLabel) == 'function') {
            boxLabel = boxLabel();
        }

        setTimeout(() => {
            if (!this.validation.hasValidated[fieldName]) {
                this.validation.hasValidated[fieldName] = true;
                this.validate(fieldName, fieldInfo, value);
            }
        }, 0);

        // Render component based on type
        switch (fieldInfo.type) {
            case 'text':
            case 'password':
            case 'number':
            case 'search':
            case 'email':
            case 'color':
            case 'tel':
                return (
                    <input
                        type={fieldInfo.type}
                        className={classNames}
                        disabled={isDisabled}
                        value={value}
                        autoFocus={fieldInfo.autoFocus}
                        onChange={e => {
                            this.setValue(fieldName, e.target.value, fieldInfo);
                            this.validate(fieldName, fieldInfo, e.target.value);
                        }}
                        onFocus={e => {
                            fieldInfo.lastValue = e.target.value;
                        }}
                        onBlur={e => {
                            this.runOnBlur(fieldName, e.target.value, fieldInfo);
                        }}
                    />
                );
            case 'checkbox':
                return (
                    <label className="checkbox-label">
                        <input
                            type="checkbox"
                            className={classNames}
                            checked={!!value}
                            disabled={isDisabled}
                            onChange={e => {
                                this.setValue(fieldName, e.target.checked, fieldInfo);
                                this.validate(fieldName, fieldInfo, e.target.checked);
                            }}
                        />
                        {boxLabel &&
                            <span>{boxLabel}</span>
                        }
                    </label>
                );
            case 'radio-boolean':
                return (
                    <span className="boolean-label">
                        <span className="form-radio-label">Yes: </span>
                        <input
                            className="form-radio-button"
                            type="radio"
                            name={fieldName}
                            disabled={isDisabled}
                            value={'true'}
                            id="true"
                            defaultChecked={!!value}
                            disabled={isDisabled}
                            onChange={e => {
                                this.setValue(fieldName, e.target.value, fieldInfo);
                                this.validate(fieldName, fieldInfo, e.target.value);
                            }
                            }
                        />
                        <span className="form-radio-label">No: </span>
                        <input
                            className="form-radio-button"
                            type="radio"
                            name={fieldName}
                            value={'false'}
                            id="false"
                            defaultChecked={value == 'false' || value == false}
                            disabled={isDisabled}
                            onChange={e => {
                                this.setValue(fieldName, e.target.value, fieldInfo);
                                this.validate(fieldName, fieldInfo, e.target.value);
                            }
                            }
                        />
                    </span>
                );
                break;
            case 'multiline-text':
                fieldInfo.rows = fieldInfo.rows || 4;
                return (
                    <textarea
                        className={classNames}
                        disabled={isDisabled}
                        value={value}
                        rows={fieldInfo.rows}
                        autoFocus={fieldInfo.autoFocus}
                        onChange={e => {
                            this.setValue(fieldName, e.target.value, fieldInfo);
                            this.validate(fieldName, fieldInfo, e.target.value);
                        }}
                        onFocus={e => {
                            fieldInfo.lastValue = e.target.value;
                        }}
                        onBlur={e => {
                            this.runOnBlur(fieldName, e.target.value, fieldInfo);
                        }}
                    />
                );
            case 'single-select': {
                fieldInfo.getOptions = fieldInfo.getOptions || (() => console.log('Please specify getOptions on field ' + fieldName));
                let options = fieldInfo.getOptions();
                if (fieldInfo.blankText) {
                    options = [{
                        value: '',
                        text: fieldInfo.blankText
                    }].concat(options);
                }

                return (
                    <select
                        className={classNames}
                        disabled={isDisabled}
                        value={value}
                        onChange={e => {
                            this.setValue(fieldName, e.target.value, fieldInfo);
                            this.validate(fieldName, fieldInfo, e.target.value);
                        }}
                    >
                        {options && options.map(o =>
                            <option key={o.value || '__BLANK__'} value={o.value}>{o.text}</option>
                        )}
                    </select>
                );
            }

            case 'checkboxes': {
                fieldInfo.getOptions = fieldInfo.getOptions || (() => console.log('Please specify getOptions on field ' + fieldName));
                const options = fieldInfo.getOptions();
                
                return options.map(o =>
                    <label key={o.value} className="checkbox-label">
                        <input
                            type="checkbox"
                            className={classNames}
                            checked={!!value[o.value]}
                            disabled={isDisabled}
                            onChange={e => {
                                this.setValue(fieldName, e.target.checked, fieldInfo, o.value);
                                this.validate(fieldName, fieldInfo, e.target.checked);
                            }}
                        />
                        {o.text &&
                            <span>{o.text}</span>
                        }
                    </label>
                )

                break;
            }

            case 'date':
                if (value) {
                    value = moment(value).toDate();
                }
                return (
                    <DatePicker
                        autoFocus={!!fieldInfo.autoFocus}
                        value={value}
                        clearIcon={null}
                        disableClock={true}
                        disabled={isDisabled}
                        format="dd/MM/yyyy"
                        onChange={date => {
                            // Strip out time information
                            if (date) {
                                date = moment(date).local().format('YYYY-MM-DD');
                            }
                            this.setValue(fieldName, date, fieldInfo)
                            this.validate(fieldName, fieldInfo, date);
                        }}
                    />
                );
            case 'checkbox':
                return (
                    <input
                        type="checkbox"
                        className={classNames}
                        checked={!!value}
                        disabled={isDisabled}
                        onChange={e => {
                            this.setValue(fieldName, e.target.checked, fieldInfo);
                            this.validate(fieldName, fieldInfo);
                        }}
                    />
                );
            case 'currency':
                return (
                    <NumberFormat
                        className={classNames}
                        thousandSeparator={','}
                        decimalSeparator={'.'}
                        prefix="£"
                        disabled={isDisabled}
                        value={value || ''}
                        onValueChange={(values) => {
                            this.setValue(fieldName, values.value);
                            this.validate(fieldName, fieldInfo, values.value);
                        }}
                    />
                );
        }
    }

    validate(fieldName, fieldInfo, value) {
        const validation = fieldInfo.validation;
        if (validation) {
            this.validation.errors[fieldName] = [];
            const fieldErrors = this.validation.errors[fieldName];
            if (validation.isRequired) {
                if (!value) {
                    fieldErrors.push(validation.requiredMessage || 'This field is required');
                }
            }
        }
        this.updateIsValid();
        if (this.onValidate) {
            this.onValidate(this.validation);
        }
    }

    updateIsValid() {
        this.validation.isValid = true;
        for (var fieldName in this.fields) {
            if (this.validation.errors[fieldName] && this.validation.errors[fieldName].length > 0) {
                this.validation.isValid = false;
                return;
            }
        }
    }

    getValidationSummary() {
        const errors = [];
        for (var fieldName in this.fields) {
            const validationErrors = this.validation.errors[fieldName];
            if (validationErrors) {
                validationErrors.forEach(e => {
                    errors.push({
                        fieldName: fieldName,
                        fieldLabel: this.fields[fieldName].label || fieldName,
                        error: e
                    })
                });
            }
        }
        return errors;
    }

    getValidationSummaryHTML() {
        const validationSummary = this.getValidationSummary();
        return ReactDOMServer.renderToString(<ul>
            {validationSummary.map(v =>
                <li>
                    <b>{v.fieldLabel}</b>: {v.error}
                </li>    
            )}
        </ul>);
    }
}
