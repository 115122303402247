// Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';

// Services & Helpers
import OnlineBookingService from 'services/OnlineBookingService';
import GlobalStateService from 'services/GlobalStateService';
import TextHelpers from 'helpers/TextHelpers';
import DateHelpers from 'helpers/DateHelpers';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import Loader from 'components/reusable/Loader';
//-------------------------------------------------------------------------------------------------------------------

class ConfirmApptPage extends Component {

    constructor(props) {
        super(props);

        // Init
        this.state = {
            isLoading: true
        };
    }

    //--------------------------------------------------------------------------------------------------------------------

    async confirm() {
        const { confirmApptInfo } = this.props;
        if (this.state.isConfirming) return;
        this.setState({ isConfirming: true });
        await OnlineBookingService.confirm(confirmApptInfo.code);
        this.props.onConfirm();
        this.setState({
            isConfirming: false
        });
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const { salonInfo, confirmApptInfo } = this.props;
        const { isConfirming } = this.state;

        let content;
        if (confirmApptInfo && confirmApptInfo.status != "cancelled") {
            content = <>
                <h1>Please confirm your appointment</h1>

                <div className="confirm-appt-field">
                    <div className="field-name">Salon name</div>
                    <div className="field-value">{salonInfo.name}</div>
                </div>

                <div className="confirm-appt-field">
                    <div className="field-name">Client name</div>
                    <div className="field-value">{confirmApptInfo.customer.name}</div>
                </div>

                <div className="confirm-appt-field">
                    <div className="field-name">Appointment date</div>
                    <div className="field-value">{moment(confirmApptInfo.date).format('dddd, Do MMMM YYYY')}</div>
                </div>

                <div className="confirm-appt-field">
                    <div className="field-name">Service(s)</div>
                    <div className="field-value">
                        {confirmApptInfo.services.map((s, index) =>
                            <div key={index}>
                                {s.serviceName} with {s.stylistName} at {moment(s.time).format('hh:mm a')}
                            </div>
                        )}
                    </div>
                </div>

                {confirmApptInfo.isConfirmed ?
                    <div className="already-confirmed">
                        Thank you for confirming your appointment ☺️
                    </div> :
                    <button type="button" className="button primary" onClick={() => this.confirm()}>
                        {isConfirming ?
                            <><Loader isInline />{' '}Please wait...</> :
                            <>Confirm</>
                        }
                    </button>
                }

                <div className="change-or-cancel-message">
                    If you need to discuss your booking, please call the salon
                    {!!salonInfo.telephone && <>{' '}on {salonInfo.telephone}</>}.
                </div>
            </>;
        } else if (confirmApptInfo && confirmApptInfo.status == "cancelled") {
            content = <>

                <h1>Appointment can't be confirmed!</h1>

                <p>
                    Sorry, this appointment has been cancelled.
                </p>
                <p className="mb-0">
                    Please contact the salon to check and confirm your appointment.
                </p>

            </>;
        } else {
            content = <>

                <h1>Appointment not found!</h1>

                <p>
                    Sorry, we couldn't find the appointment you're looking for.
                </p>
                <p>
                    It may have been deleted or already checked out.
                </p>
                <p className="mb-0">
                    Please contact the salon to check and confirm your appointment.
                </p>

            </>;
        }

        return (
            <div className="page-content">
                <div className="panel confirm-appt-panel">
                    <div className="panel-body">
                        {content}
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(ConfirmApptPage);