// Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router';

// Services & Helpers
import OnlineBookingService from 'services/OnlineBookingService';

class SalonListPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            salonList: []
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        let salonList = await OnlineBookingService.getSalonList();

        this.setState({
            salonList
        });
    }

    renderSalon() {
        const { salonList } = this.state;

        if (salonList.length == 0) {
            return (
                <div className="panel floating-panel">
                    <div className="panel-body">
                        Sorry, no salons found for this group code.
                    </div>
                </div>
            );
        }
        return (<>
            <div className="page-content">
                <div className="page-content-left">
                    <div className="panel salon-panel">
                        {salonList.length > 0 &&
                            <>
                                <div className="panel-header">
                                    Please select a salon.
                                </div>
                                <div className="panel-body">
                                    <ul className="salon-list">
                                        {salonList.map(s =>
                                            <li className="salon-name hollow-button" onClick={() => window.location.replace('/' + s.code)}>
                                                {s.name}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </>
                        }

                    </div>
                </div>
            </div>
        </>)
    }

    render() {
        return (<>
            {this.renderSalon()}
        </>
        )
    }

}


export default withRouter(SalonListPage);