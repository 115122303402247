import moment from 'moment';

export default class DateHelpers {

    static stripTime(dateTime) {
        const dateTimeMoment = moment(dateTime);
        const dateStr = dateTimeMoment.format('YYYY-MM-DD') + 'T00:00';
        const offset = moment(dateTime).toDate().getTimezoneOffset();
        const dateMoment = moment(dateStr);
        dateMoment.add(-offset, 'minutes');
        return dateMoment.toDate();
    }

    static parseTimeToNumMins(time) {
        const timeParts = time.split(':');
        return parseInt(timeParts[0]) * 60 + parseInt(timeParts[1]);
    }

    static listTimes(interval, earliest) {
        const results = [];
        interval = parseInt(interval);
        if (!interval || interval <= 0) return results;
        let earliestMins = 0;
        if (earliest) {
            earliestMins = DateHelpers.parseTimeToNumMins(earliest);
        }
        let numHours = 0, numMins = 0;
        let totalNumMins = 0;
        while (numHours < 24) {
            const time = (numHours < 10 ? '0' : '') + numHours + ':' + (numMins < 10 ? '0' : '') + numMins;
            if (totalNumMins >= earliestMins) {
                results.push({
                    value: time,
                    text: time
                });
            }
            totalNumMins += interval;
            numMins += interval;
            while (numMins >= 60) {
                numMins -= 60;
                numHours++;
            }
        }
        return results;
    }

    static findNearestTime(oldTime, newTimes) {
        oldTime = DateHelpers.parseTimeToNumMins(oldTime);
        let lastNewTime = null;
        for (var i = 0; i < newTimes.length; i++) {
            const newTime = newTimes[i];
            const newTimeMins = DateHelpers.parseTimeToNumMins(newTime.value);
            if (newTimeMins == oldTime) {
                return newTime.value;
            } else if (newTimeMins > oldTime) {
                return lastNewTime;
            }
            lastNewTime = newTime.value;
        }
    }

    static removeSeconds(timeSpan, convertTo12Hour) {
        if (!timeSpan) return '';
        const parts = timeSpan.split(':');
        parts.splice(2);

        if (convertTo12Hour) {
            const numHours = parseInt(parts[0]);
            if (numHours >= 12) {
                if (numHours > 12) {
                    parts[0] = numHours - 12;
                }
                parts[1] += ' PM';
            } else {
                parts[1] += ' AM';
            }
        }
        parts[0] = parseInt(parts[0]);
        return parts.join(':');
    }

    static convertTimeSpanToDate(timeSpan) {
        const parts = timeSpan.split(':');
        return new Date(2000, 0, 1, Number(parts[0]), Number(parts[1]), 0);
    }

}
