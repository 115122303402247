import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Services & Helpers
import EmailService from 'services/EmailService';

class Unsubscribe extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            guid: '',
            isUnsubscribe: true
        };
    }

    componentDidMount() {
        let guid = '';
        if (this.props.match.params)
            guid = this.props.match.params.guid;

        this.setState({
            guid
        }, () => {
            this.load();
        });
    }

    async load() {
        await EmailService.unsubscribe(this.state.guid, this.state.isUnsubscribe);
    }

    async onSubscribe(e) {
        const { guid, isUnsubscribe } = this.state;
        e.preventDefault()
        this.setState({
            isUnsubscribe: !isUnsubscribe
        }, () => {
            EmailService.unsubscribe(guid, this.state.isUnsubscribe);
        });
    }

    render() {
        return (
            <div className="panel">
                <div className="panel-body">
                    {this.renderInner()}
                </div>
            </div>
        );
    }

    renderUnsubscribe() {
        return (
            <>
                <div>
                    <h2>
                        Thank you, you have been successfully unsubscribed.
                    </h2>
                    <p className="subscribeLink">
                        Unsubscribed by mistake? <a href="#" onClick={e => this.onSubscribe(e)}>[Resubscribe]</a>
                    </p>
                </div>
            </>
        )
    }

    renderSubscribe() {
        return (
            <>
                <div>
                    <h2>
                        Welcome back! You have been re-subscribed.
                    </h2>
                    <p className="subscribeLink">
                        Subscribed by mistake? <a href="#" onClick={e => this.onSubscribe(e)}>[Unsubscribe]</a>
                    </p>
                </div>
            </>
        )
    }

    renderInner() {
        const { isUnsubscribe } = this.state;
        return (<>
            <div>
                {isUnsubscribe ?
                    <>
                        {this.renderUnsubscribe()}
                    </>
                    :
                    <>
                        {this.renderSubscribe()}
                    </>
                }
            </div>
        </>)
    }
}

export default withRouter(Unsubscribe);