import React, { Component } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import GlobalStateService from 'services/GlobalStateService';
import OnlineBookingService from 'services/OnlineBookingService';

import Loader from 'components/reusable/Loader';

class FormsPanel extends Component {

    constructor(props) {
        super(props);

        this.selectForm = this.selectForm.bind(this);

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const loginDetails = GlobalStateService.getValue('loginDetails');
        var forms = await OnlineBookingService.getCustomerForms(loginDetails.customer.customerID);
        this.setState({
            forms: forms,
            isLoading: false
        })
    }

    async selectForm(form) {
        this.props.history.push("/form/" +  form.formID );
    }

    render() {
        const {
            forms
        } = this.state;

        if (this.state.isLoading) {
            return (
                <div className="panel">
                    <div class="panel-header">
                        Consultation Forms
                    </div>
                    <div class="panel-body">
                        <Loader />
                    </div>
                </div>
            );
        }

        return (
            <div className="panel">
                <div class="panel-header">
                    Consultation Forms
                </div>
                <div class="panel-body">
                    {forms.length == 0 && <>No forms</> }
                    {forms.length > 0 &&
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Form Type</th>
                                    <th>Form Status</th>
                                    <th className="actions-column"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {forms.map(form =>
                                    <tr key={form.formID}>
                                        <td>
                                            {form.formType.name}
                                        </td>
                                        <td>
                                            {!form.filledDate && form.createdDate && <>
                                                Created {moment(form.createdDate).format('DD-MM-YYYY')}
                                            </>}
                                            {form.filledDate && <>
                                                Completed {moment(form.filledDate).format('DD-MM-YYYY')}
                                            </>}
                                        </td>
                                        <td className="actions-column">
                                            <button className="button button-primary button-small" onClick={e => this.selectForm(form)} >
                                                <span className="fas fa-clipboard"></span>{' '}
                                                {!form.filledDate && <>Fill out now</>}
                                                {form.filledDate && <>View</>}
                                            </button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(FormsPanel);