// Libs
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as bootstrap from 'bootstrap/dist/js/bootstrap.min.js';

// Components
import App from 'App';

// JS 
import "bootstrap/dist/js/bootstrap.min.js";

// CSS 
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './scss/main.scss'

// https://stackoverflow.com/questions/28241912/bootstrap-modal-in-react-js
// https://github.com/makeusabrew/bootbox/issues/777
window.jQuery = $;
window.$ = $;
global.jQuery = $;
$.fn.modal = bootstrap.Modal.jQueryInterface
$.fn.modal.Constructor = bootstrap.Modal

//-------------------------------------------------------------------------------------------------------------------

const rootElement = document.getElementById('root');
window.$ = $;

const baseURL = () => {
    let salonCode = window.location.pathname;
    if (salonCode.split('/').length >= 1) {
        salonCode = salonCode.split('/')[1];
    } else {
        salonCode = '';
    }
    return salonCode;
};

ReactDOM.render(
    <BrowserRouter basename={baseURL()}>
        <App />
    </BrowserRouter>,
    rootElement);

//registerServiceWorker();

