// Components
import React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';

// CSS
import 'react-datepicker/dist/react-datepicker.css';

// Locale
import enGB from 'date-fns/locale/en-gb';
registerLocale('en-gb', enGB)

export default class DatePicker extends React.Component {

    render() {
        const props = {
            ...this.props
        };

        return (
            <ReactDatePicker
                selected={props.value}
                clearIcon={null}
                dateFormat="dd/MM/yyyy"
                locale="en-gb"
                {...props}
            />
        );
    }

}
