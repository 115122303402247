// Libs
import React, { Component } from 'react';
import queryString from 'query-string';
import { PaymentIcon } from 'react-svg-credit-card-payment-icons';

// Services & Helpers
import GlobalStateService from 'services/GlobalStateService';
import OnlineBookingService from 'services/OnlineBookingService';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import StripeForm from 'components/pages/booking/StripeForm';
import EvoForm from 'components/pages/booking/EvoForm';
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class PaymentForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const { salonInfo, customer, depositAmount } = this.props;
        const loginDetails = GlobalStateService.getValue('loginDetails');
        const customerId = loginDetails && loginDetails.customer ? loginDetails.customer.customerID : customer ? customer.customerID : null;
        let savedCard = customerId ?  await OnlineBookingService.getSavedCard(customerId) : false;

        if (savedCard && savedCard.consentToSaveCard) {
            if (savedCard && !savedCard.stripeId) {
                this.setState({ providerType: 'evo' });
            } else
                this.setState({ providerType: 'stripe' });
        }

        if ((salonInfo.paymentProvider != this.state.providerType) || (depositAmount == 0)) {
            savedCard = false;
        }

        this.setState({ savedCard });
    }

    addNewCard() {
        if (!this.state.savedCard.stripeId) {
            BootboxHelper.alert('Please tick to agree Terms and Conditions before proceeding');
        }
        this.setState({ savedCard: false });
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const { salonInfo, isConfirmApptPage } = this.props;
        const { isLoading } = this.state;
        const loginDetails = GlobalStateService.getValue('loginDetails');
        if ((isLoading || !loginDetails) && (!isConfirmApptPage)) {
            return (
                <div className="panel">
                    <div className="please-wait">
                        <Loader />
                    </div>
                </div>
            );
        }

        if (salonInfo.paymentProvider == 'stripe') {
            return this.renderStripeForm();
        }
        else if (salonInfo.paymentProvider == 'evo') {
            return this.renderEvoForm();
        }
    }

    renderStripeForm() {
        const { salonInfo, booking, customer, depositAmount, isConfirmApptPage } = this.props;
        const { acceptTermsConditions, saveCardDetails, isBusy, savedCard, providerType, proceedWithSavedCard } = this.state;
        const loginDetails = GlobalStateService.getValue('loginDetails');
        const amount = booking ? booking.depositAmount : depositAmount;
        return (
            <StripeForm
                booking={booking}
                isDisabled={isBusy}
                customer={loginDetails ? loginDetails.customer : customer}
                stripePublicKey={salonInfo.stripePublicKey}
                onError={(error) => {
                    BootboxHelper.alert(error);
                }}
                depositAmount={depositAmount}
                acceptTermsConditions={acceptTermsConditions}
                saveCardDetails={saveCardDetails}
                proceedWithSavedCard={proceedWithSavedCard}
                savedCard={providerType == 'stripe' ? savedCard : false}
                isConfirmApptPage={isConfirmApptPage}
                setIsBusy={(isBusy) => {
                    this.setState({ isBusy })
                    this.props.setIsBusy(isBusy);
                }}
                onComplete={() => {
                    this.props.onComplete();
                }}
                render={(cardDetails, submit) =>
                    <>
                        <div className="panel deposit-payment">
                            <div className="panel-header bg-colour-3">
                                {amount > 0 ? 'Deposit Payment' : 'Card details'}
                            </div>
                            <div className="panel-body">
                                {salonInfo.depositPageMessage &&
                                    <p className="deposit-message">
                                        {salonInfo.depositPageMessage}
                                    </p>
                                }

                                {!savedCard &&
                                    <p className="please-enter-card-details">
                                        Please enter your card details below:
                                    </p>
                                }
                                {savedCard && <>
                                    <p className="please-enter-card-details">
                                        Please find your saved card details below:
                                    </p>
                                    <div class="panel">
                                        {/* <span className="saved-card-brand">{savedCard.funding.toUpperCase()} CARD</span>*/}
                                        <div className="panel-body saved-card-details">
                                            <ul className="list service-list">
                                                <li className="non-selectable">
                                                    <span className="card-brand">
                                                        <PaymentIcon type={savedCard.brand.toLowerCase()} format="flatRounded" width={50} />
                                                    </span>
                                                    <span className="saved-card-brand">
                                                        <label><b>{savedCard.brand.toUpperCase()}</b> ending in {savedCard.last4Digits}</label>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="cardDetails">
                                                        <label>Expires : {new Date(savedCard.expiryDate).getMonth() + 1}/{new Date(savedCard.expiryDate).getFullYear()}</label>
                                                    </span>
                                                </li>
                                                <li>
                                                    <p className="cardDetails">
                                                        <label>Name : {savedCard.name}</label>
                                                    </p>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </>
                                }
                                {!savedCard && cardDetails}
                                <p>
                                    <p className="agree-terms-conditions">
                                        <input
                                            id="agree-tcs"
                                            type="checkbox"
                                            checked={acceptTermsConditions || false}
                                            onChange={e => this.setState({ acceptTermsConditions: e.target.checked })}
                                        />
                                        <span>
                                            <label htmlFor="agree-tcs">
                                                I agree to the
                                            </label>
                                            {' '}<a href={salonInfo.termsConditionsLink} target="_blank">Terms and Conditions</a>
                                        </span>
                                        {!savedCard &&
                                            <p className="save-card-details">
                                                <input id="save-card"
                                                    type="checkbox"
                                                    checked={saveCardDetails || false}
                                                    onChange={e => this.setState({ saveCardDetails: e.target.checked })} />
                                                <span>
                                                    <label htmlFor="save-card">
                                                        Save card details for future payments
                                                    </label>

                                                </span>
                                            </p>
                                        }
                                        {savedCard &&
                                            <>
                                                <p className="save-card-details">
                                                    <input id="saved-card"
                                                        type="checkbox"
                                                        checked={proceedWithSavedCard || false}
                                                        onChange={e => this.setState({ proceedWithSavedCard: e.target.checked })} />
                                                    <span>
                                                        <label htmlFor="save-card">
                                                            Continue with saved card details
                                                        </label>
                                                    </span>
                                                </p>
                                                <p className="add-new-card">
                                                    <button className="button primary" onClick={() => this.addNewCard()}>
                                                        <span className="fa fa-plus"></span>{' '}
                                                        Add New Card
                                                    </button></p>
                                            </>
                                        }
                                    </p>
                                </p>
                                {isBusy &&
                                    <div className="submitting">
                                        <Loader isInline />
                                        <p>Please wait while we process your payment.</p>
                                        <p>Please don't refresh or close this screen.</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <button
                            type="button"
                            className="button primary confirm-booking-button"
                            style={{ width: '100%', fontSize: 26, marginTop: 0 }}
                            onClick={isBusy ? null : submit}
                            disabled={isBusy}
                        >
                            {isBusy && <><Loader isInline />{' '}</>}
                            Confirm Request
                        </button>
                    </>
                }
            />)

    }

    renderEvoForm() {
        const { salonInfo, booking, customer, depositAmount } = this.props;
        const { acceptTermsConditions, isBusy, savedCard, providerType, proceedWithSavedCard, isPaymentPageLoading } = this.state;
        const loginDetails = GlobalStateService.getValue('loginDetails');
        const amount = booking ? booking.depositAmount : depositAmount;
        return (<EvoForm
            booking={booking}
            isDisabled={isBusy}
            customer={loginDetails ? loginDetails.customer : customer}
            onError={(error) => {
                BootboxHelper.alert(error);
            }}
            providerType={providerType}
            acceptTermsConditions={acceptTermsConditions}
            savedCard={providerType == 'evo' ? savedCard : false}
            proceedWithSavedCard={proceedWithSavedCard}
            salonInfo={salonInfo}
            depositAmount={depositAmount}
            setIsBusy={(isBusy) => {
                this.setState({ isBusy })
                this.props.setIsBusy(isBusy);
            }}
            setIsPaymentPageLoading={(isPaymentPageLoading) => {
                this.setState({ isPaymentPageLoading })
            }}
            onComplete={() => {
                this.props.onComplete();
            }}
            render={(cardDetails, submit) => <>

                <div className="panel deposit-payment">
                    <div className="panel-header bg-colour-3">
                        {amount > 0 ? 'Deposit Payment' : 'Card details'}
                    </div>
                    <div className="panel-body">
                        {amount > 0 && salonInfo.depositPageMessage &&
                            <p className="deposit-message">
                                {salonInfo.depositPageMessage}
                            </p>
                        }
                        {amount == 0 && salonInfo.captureCardDetailsMessage &&
                            <p className="capture-card-details-message">
                                {salonInfo.captureCardDetailsMessage}
                            </p>
                        }

                        {!savedCard ?
                            <p className="please-enter-card-details">
                                Please enter your card details below:
                            </p> :
                            <p className="please-enter-card-details">
                                Please find your saved card details below:
                            </p>
                        }

                        {!savedCard && cardDetails}
                        {savedCard && <>
                            <div class="panel">
                                {/*<span className="saved-card-brand">{savedCard.funding.toUpperCase()} CARD</span>*/}
                                <div className="panel-body saved-card-details">
                                    <ul className="list service-list">
                                        <li className="non-selectable">
                                            <span className="card-brand">
                                                <PaymentIcon type={savedCard.brand.toLowerCase()} format="flatRounded" width={50} />
                                            </span>
                                            <span className="saved-card-brand">
                                                <label><b>{savedCard.brand.toUpperCase()}</b> ending in {savedCard.last4Digits}</label>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="cardDetails">
                                                <label>Expires : {new Date(savedCard.expiryDate).getMonth() + 1}/{new Date(savedCard.expiryDate).getFullYear()}</label>
                                            </span>
                                        </li>
                                        <li>
                                            <p className="cardDetails">
                                                <label>Name : {savedCard.name}</label>
                                            </p>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </>
                        }
                        {savedCard && <>
                            <p className="agree-terms-conditions">
                                <input
                                    id="agree-tcs"
                                    type="checkbox"
                                    checked={acceptTermsConditions || false}
                                    onChange={e => this.setState({ acceptTermsConditions: e.target.checked })}
                                />
                                <span>
                                    <label htmlFor="agree-tcs">
                                        I agree to the
                                    </label>
                                    {' '}<a href={salonInfo.termsConditionsLink} target="_blank">Terms and Conditions</a>
                                </span>

                                <p className="save-card-details">
                                    <input id="saved-card"
                                        type="checkbox"
                                        checked={proceedWithSavedCard || false}
                                        onChange={e => this.setState({ proceedWithSavedCard: e.target.checked })} />
                                    <span>
                                        <label htmlFor="save-card">
                                            Continue with saved card details
                                        </label>
                                    </span>
                                </p>
                                <p className="add-new-card">
                                    <button className="button primary" onClick={() => this.addNewCard()}>
                                        <span className="fa fa-plus"></span>{' '}
                                        Add New Card
                                    </button>
                                </p>
                            </p>
                        </>
                        }

                        {isBusy &&
                            <div className="submitting">
                                <Loader isInline />
                                <p>Please wait while we process your payment.</p>
                                <p>Please don't refresh or close this screen.</p>
                            </div>
                        }

                        {isPaymentPageLoading &&
                            <div className="submitting">
                                <Loader isInline />
                            </div>
                        }
                    </div>
                </div>
                {savedCard && providerType == 'evo' &&
                    <button
                        type="button"
                        className="button primary confirm-booking-button"
                        style={{ width: '100%', fontSize: 26, marginTop: 0 }}
                        onClick={isBusy ? null : submit}
                        disabled={isBusy}
                    >
                        {this.props.isBusy && <><Loader isInline />{' '}</>}
                        Confirm Request
                    </button>
                }
            </>
            }
        />)
    }
}
export default PaymentForm;